const blockCapabilities = async($block) => {
	const CapabilitiesModule = await import('../classes/capabilities');
	const Capabilities = CapabilitiesModule.default;

	new Capabilities($block);
};

const checkEditor = ($block) => {
	const isPreview = $block.find('.site-editor-preview').length;
	if ( !isPreview ) {
		blockCapabilities($block);
	}
};

themeUtils.loadBlock( checkEditor, 'capabilities', '.section-capabilities' );
